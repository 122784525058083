import React, { useCallback, useEffect, useState } from 'react'
import { Location, NavigateFunction, useLocation, useNavigate } from 'react-router-dom'
import {
  AppBar,
  Badge,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material'
import {
  AccountCircle as AccountCircleIcon,
  ContentPasteSearch as ContentPasteSearchIcon,
  Help,
  InfoOutlined as InfoOutlinedIcon,
  InsertCommentOutlined as InsertCommentOutlinedIcon,
  Logout as LogoutIcon,
  PersonOutlineOutlined as PersonOutlineOutlinedIcon,
} from '@mui/icons-material'
import { t, Trans } from '@lingui/macro'
import MenuIcon from '@mui/icons-material/Menu'
import ClipboardDrawer from 'components/sts-app-bar/ClipboardDrawer'
import LanguageSelector from 'components/sts-app-bar/LanguageSelector'
import { colors } from 'shared/theme'
import { useDrawerComponentContext } from 'contexts/DrawerComponentContext'
import paths from 'utils/paths'
import ClipboardContext, { IClipboard } from 'contexts/ClipboardContext'
import { useStudyStatesContext } from 'contexts/StudyStatesContext'

type STSAppBarProps = {
  language: string
  onChangeLanguage: (newLanguage: string) => void
}

function STSAppBar({ language, onChangeLanguage }: STSAppBarProps): JSX.Element {
  console.log('Load STSAppBar Component')

  const location: Location = useLocation()
  const navigate: NavigateFunction = useNavigate()
  const [clipboardDrawerOpen, setClipboardDrawerOpen] = useState<boolean>(false)
  const [title, setTitle] = useState<string>(t`STS42`)
  const [aboutOpen, setAboutOpen] = useState<boolean>(false)
  const [userAnchorEl, setUserAnchorEl] = React.useState<HTMLElement | null>(null)
  const { drawerOpen, setDrawerOpen } = useDrawerComponentContext()
  const { pasteMenuCount } = useStudyStatesContext()

  useEffect(() => {
    let title: string = t`STS42`
    if (location.pathname === '/') {
      title += ` - ${t`Studies`}`
    } else if (location.pathname.includes('/teams/')) {
      title += ` - ${t`Team`}`
    } else if (location.pathname.startsWith('/study/')) {
      title += ` - ${t`Study`}`
    } else if (
      location.pathname.startsWith('/account-edit/me') ||
      location.pathname.startsWith('/password-edit/me')
    ) {
      title += ` - ${t`Profile`}`
    }
    document.title = title
    setTitle(title)
  }, [location.pathname])

  const handleOpenAbout = (): void => {
    setUserAnchorEl(null)
    setAboutOpen(true)
  }

  const handleCloseAbout = (): void => {
    setAboutOpen(false)
  }

  const handleOpenClipboardDrawer = (): void => {
    setClipboardDrawerOpen(true)
  }

  const handleCloseClipboardDrawer = useCallback((): void => {
    setClipboardDrawerOpen(false)
  }, [])

  const handleOpenUserMenu = (e: React.MouseEvent<HTMLElement>) => {
    setUserAnchorEl(e.currentTarget)
  }

  const handleCloseUserMenu = () => {
    setUserAnchorEl(null)
  }

  const handleAccountEditClick = (): void => {
    setUserAnchorEl(null)
    navigate('/account-edit/me')
  }

  const handleLogoutClick = (): void => {
    setUserAnchorEl(null)
    navigate('/auth/logout')
  }

  document.title = title

  const isRouteWithSideMenuDrawer = (): boolean => {
    const { pathname } = location

    if (pathname)
      return (
        pathname === paths.frontend.home ||
        pathname.includes(paths.frontend.projects) ||
        pathname.includes(paths.frontend.teams()) ||
        pathname.includes(paths.frontend.study())
      )

    return false
  }

  return (
    <AppBar position={isRouteWithSideMenuDrawer() ? 'fixed' : 'static'}>
      <Toolbar sx={{ height: '100%' }}>
        <Box
          display="flex"
          component="a"
          href={paths.frontend.home}
          flexGrow={1}
          alignItems="center"
          sx={{ textDecoration: 'none' }}
        >
          <Box
            component="img"
            sx={{
              height: '30px',
              width: 'auto',
            }}
            src="/logo80-white.png"
          />

          <Box display="flex" margin="0 0 0 8px">
            <Typography variant="h6" color="white">
              STS42
            </Typography>
          </Box>
        </Box>

        <Box display={{ xs: 'flex', md: 'none' }} alignItems="center">
          <IconButton
            onClick={() => setDrawerOpen(!drawerOpen)}
            size="medium"
            sx={{ color: colors.white.A500 }}
          >
            <MenuIcon />
          </IconButton>
        </Box>

        <Box display={{ xs: 'none', md: 'flex' }} alignItems="center">
          <Tooltip title={t`Open clipboard panel`} disableInteractive>
            <IconButton color="inherit" onClick={handleOpenClipboardDrawer} size="small">
              <Badge badgeContent={pasteMenuCount} color="warning">
                <ContentPasteSearchIcon />
              </Badge>
            </IconButton>
          </Tooltip>
          <LanguageSelector
            language={language}
            onChangeLanguage={onChangeLanguage}
            aria-label="language"
          />
          <a
            href={t`https://documentation.sts42.com`}
            style={{ color: 'inherit', textDecoration: 'none' }}
            target="documentation"
            aria-label="help"
          >
            <Tooltip title={t`Documentation`} disableInteractive>
              <IconButton color="inherit" size="small">
                <Help fontSize="medium" />
              </IconButton>
            </Tooltip>
          </a>
          <Box>
            <Tooltip title={t`Open user menu`} disableInteractive>
              <IconButton
                aria-controls="user-menu-appbar"
                aria-haspopup="true"
                color="inherit"
                onClick={handleOpenUserMenu}
                sx={{ ml: 2, px: 0 }}
              >
                <AccountCircleIcon fontSize="large" />
              </IconButton>
            </Tooltip>
            <Menu
              anchorEl={userAnchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              id="user-menu-appbar"
              keepMounted
              onClose={handleCloseUserMenu}
              open={Boolean(userAnchorEl)}
              sx={{ mt: '30px' }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              {localStorage.getItem('user_name') ? (
                <MenuItem onClick={handleAccountEditClick} sx={{ py: '4px' }}>
                  <ListItemIcon>
                    <PersonOutlineOutlinedIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography sx={{ fontSize: '0.95rem' }}>
                      {localStorage.getItem('user_name')}
                    </Typography>
                  </ListItemText>
                </MenuItem>
              ) : (
                <span />
              )}
              <Divider sx={{ my: '8px' }} />
              <a
                href="https://forms.clickup.com/f/dgbxq-7640/OC5XD2091JDD91JT8M"
                style={{ color: 'inherit', textDecoration: 'none' }}
                target="feedbackform"
              >
                <MenuItem onClick={handleCloseUserMenu} sx={{ py: '4px' }}>
                  <ListItemIcon>
                    <InsertCommentOutlinedIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography sx={{ fontSize: '0.95rem' }}>{t`Feedback Form`}</Typography>
                  </ListItemText>
                </MenuItem>
              </a>
              <a
                href={t`https://documentation.sts42.com`}
                style={{ color: 'inherit', textDecoration: 'none' }}
                target="documentation"
              >
                <MenuItem onClick={handleCloseUserMenu} sx={{ py: '4px' }}>
                  <ListItemIcon>
                    <Help fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography sx={{ fontSize: '0.95rem' }}>{t`User documentation`}</Typography>
                  </ListItemText>
                </MenuItem>
              </a>{' '}
              <MenuItem onClick={handleOpenAbout} sx={{ py: '4px' }}>
                <ListItemIcon>
                  <InfoOutlinedIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>
                  <Typography sx={{ fontSize: '0.95rem' }}>{t`About STS42`}</Typography>
                </ListItemText>
              </MenuItem>
              <Divider />
              <MenuItem onClick={handleLogoutClick} sx={{ py: '4px' }}>
                <ListItemIcon>
                  <LogoutIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>
                  <Typography sx={{ fontSize: '0.95rem' }}>{t`Logout`}</Typography>
                </ListItemText>
              </MenuItem>
            </Menu>
          </Box>
        </Box>
      </Toolbar>
      <ClipboardContext.Consumer>
        {(clipboard: IClipboard) => (
          <ClipboardDrawer
            onClose={handleCloseClipboardDrawer}
            open={clipboardDrawerOpen}
            clipboard={clipboard}
          />
        )}
      </ClipboardContext.Consumer>
      <Dialog
        aria-describedby="about-dialog-description"
        aria-labelledby="about-dialog-title"
        onClose={handleCloseAbout}
        open={aboutOpen}
      >
        <DialogTitle id="about-dialog-title">{t`About STS42`}</DialogTitle>
        <Divider />
        <DialogContent>
          <DialogContentText id="about-dialog-description" variant="body2" textAlign="justify">
            <p>
              <Trans>
                Welcome to STS, your one-stop solution to collaborate with others and with AI to get
                things done! Our user-friendly interface allows you to easily connect with ChatGPT
                and other AI systems, giving you more control over your AI inputs and maximizing the
                usefulness of your AI outputs.
              </Trans>
            </p>
            <p>
              <Trans>
                With STS, you can streamline your workflow, enhance productivity, and unlock new
                possibilities for innovation. Say goodbye to complex AI integration and cumbersome
                “prompting” – STS makes it simple and efficient to harness the power of AI in your
                work.
              </Trans>
            </p>
            <p>
              <Trans>
                Experience the future of AI-powered collaboration with STS. Join us today and
                revolutionize the way you work. Let STS empower you to achieve more, faster, and
                with greater precision. The possibilities are endless!
              </Trans>
            </p>
            <br />
            <p>
              <strong>
                <Trans>Terms & Conditions:</Trans>
              </strong>
            </p>
            <ol>
              <li>
                <Trans>
                  By using our software, you agree to abide by all applicable laws and regulations.
                </Trans>
              </li>
              <li>
                <Trans>
                  We provide a user interface to connect and work with various services, including
                  ChatGPT. Terms and conditions from these vendors also apply to using our service.
                </Trans>
              </li>
              <li>
                <Trans>
                  You are responsible for maintaining the security of your account and any
                  information shared through our software.
                </Trans>
              </li>
              <li>
                <Trans>
                  We may collect and use data to improve our services, but we will never sell your
                  personal information to third parties.
                </Trans>
              </li>
              <li>
                <Trans>
                  We reserve the right to suspend or terminate your account if you violate these
                  terms or engage in any illegal activities.
                </Trans>
              </li>
              <li>
                <Trans>
                  Our software is provided `&quot;as is`&quot; and we make no warranties or
                  guarantees regarding its performance or reliability.
                </Trans>
              </li>
              <li>
                <Trans>
                  We may update these terms at any time, and it is your responsibility to review
                  them periodically.
                </Trans>
              </li>
              <li>
                <Trans>
                  If you have any questions or concerns about these terms, please contact us at
                  privacy@sts42.com.
                </Trans>
              </li>
            </ol>
            <p>
              <Trans>
                Copyright 2020-2024. All rights reserved, Strategic Thinking Systems Inc.
              </Trans>
            </p>
            <p>V20240917</p>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ pb: 2, pr: 3 }}>
          <Button
            autoFocus
            color="primary"
            onClick={handleCloseAbout}
            size="small"
            variant="outlined"
          >
            {t`Got it!`}
          </Button>
        </DialogActions>
      </Dialog>
    </AppBar>
  )
}

export default React.memo(STSAppBar)
